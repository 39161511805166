<course-auth-form-layout [hideIllustration]="true">
    <div class="formArea">
        <h1 *ngIf="!success" i18n="@@activateLicense.activateLicense">Activate your license</h1>

        <ng-container *ngIf="success">
            <div
                class="dialog success"
            >
                <h2 i18n="@@activateLicense.successTitle">License has been activated!</h2>

                <p
                    i18n="@@activateLicense.success"
                >You now have access to the course!</p>
            </div>

            <p class="alignRight">
                <a [routerLink]="'/' + navigationService.getRouteTranslation('course')" class="keyboardButton">
                    <span i18n="@@activateLicense.toCourse">Start the course</span>
                </a>
            </p>
        </ng-container>

        <ng-container *ngIf="!success">
            <div *ngIf="activeUser" class="dialog info">
                <h2 i18n="@@activateLicense.loggedInNoticeTitle">You are currently logged in</h2>
                <p i18n="@@activateLicense.loggedInNotice">Your license will be activated to the account you are currently logged into!</p>
            </div>

            <ng-container *ngIf="licenseAlreadyClaimed">
                <div class="dialog error">
                    <h2 i18n="@@activateLicense.licenseAlreadyClaimedTitle">License code already in use</h2>
                    <p
                        i18n="@@activateLicense.licenseAlreadyClaimed"
                    >This license code was already claimed before. Each license code can only be used once.</p>
                    <p
                        *ngIf="!activeUser"
                        i18n="@@activateLicense.licenseAlreadyClaimedLogIn"
                    >If you already activated your license before, you can <a routerLink="/login">proceed to the login page</a> to access the course.</p>
                </div>

                <p *ngIf="!activeUser" class="listActions">
                    <a routerLink="/login" mat-flat-button color="primary">
                        <mat-icon>login</mat-icon>
                        <ng-container i18n="@@login.login">Log in</ng-container>
                    </a>
                </p>
            </ng-container>

            <div *ngIf="invalidLicense" class="dialog error">
                <h2 i18n="@@activateLicense.invalidLicenseTitle">Invalid license code</h2>
                <p
                    i18n="@@activateLicense.invalidLicense"
                >This license code is invalid. Please check again if the code matches the one you received by e-mail upon purchase.</p>
            </div>

            <div *ngIf="missingBasicProduct" class="dialog error">
                <h2 i18n="@@activateLicense.missingBasicProductTitle">Access to the basic course required</h2>
                <p
                    i18n="@@activateLicense.missingBasicProduct"
                >In order to activate this add-on, access to the basic course is required.</p>
            </div>

            <ng-container *ngIf="emailAlreadyExists">
                <div class="dialog error">
                    <h2 i18n="@@activateLicense.emailAlreadyExistsTitle">E-mail already in use</h2>
                    <ng-container i18n="@@activateLicense.emailAlreadyExists">
                        <p>There is already an account registered on this e-mail address.</p>
                        <p>
                            To activate a new license in your existing account, simply
                            <a routerLink="/login">login to your account</a>.
                            Further instructions will be provided after logging in.
                        </p>
                    </ng-container>
                </div>

                <p class="listActions">
                    <a routerLink="/login" mat-flat-button color="primary">
                        <mat-icon>login</mat-icon>
                        <ng-container i18n="@@login.login">Log in</ng-container>
                    </a>
                </p>
            </ng-container>


            <form [formGroup]="form" (submit)="submit()">
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@licenseCode">License code</mat-label>
                        <input matInput required formControlName="licenseCode">
                        <mat-hint i18n="@@activateLicense.licenseCodeHint">Sent to you upon purchase</mat-hint>
                        <mat-error *ngIf="errors?.license_code">
                            <ng-container *ngFor="let err of errors.license_code">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <ng-container *ngIf="!activeUser">
                    <p>
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@email">E-mail</mat-label>
                            <input type="email" matInput required formControlName="email">
                            <mat-error *ngIf="errors?.email">
                                <ng-container *ngFor="let err of errors.email">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@activateLicense.choosePassword">Choose a password</mat-label>
                            <input
                                matInput
                                required
                                [type]="hidePassword ? 'password' : 'text'"
                                tabindex="1"
                                formControlName="password"
                                autocomplete="new-password"
                            />
                            <button
                                type="button"
                                mat-icon-button
                                matSuffix
                                (click)="hidePassword = !hidePassword"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hidePassword"
                            >
                                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                            </button>
                            <mat-error *ngIf="errors?.password">
                                <ng-container *ngFor="let err of errors.password">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@firstName">First name</mat-label>
                            <input matInput required formControlName="firstName">
                            <mat-error *ngIf="errors?.first_name">
                                <ng-container *ngFor="let err of errors.first_name">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@name">Name</mat-label>
                            <input matInput required formControlName="name">
                            <mat-error *ngIf="errors?.name">
                                <ng-container *ngFor="let err of errors.name">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-checkbox
                            required
                            formControlName="acceptTerms"
                            i18n="@@acceptTerms"
                        >I have read and accept the <a routerLink="/terms" target="_blank">Terms of Service</a> and <a routerLink="/privacy" target="_blank">Privacy Policy</a>.</mat-checkbox>
                        <mat-error *ngIf="errors?.accept_terms">
                            <ng-container *ngFor="let err of errors.accept_terms">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </p>

                    <p>
                        <mat-checkbox required formControlName="waiveRightToWithdraw">
                            <span
                                i18n="@@activateLicense.waiveRightToWithdraw"
                            >All purchases can be refunded within 14 days after purchase, as long as the license codes involved have not been activated.<br />EU residents have a right at law to withdraw from a purchase until 14 days after that purchase.<strong>However, by activating this license you expressly consent to waive your right to withdraw from this purchase.</strong></span>
                        </mat-checkbox>
                        <mat-error *ngIf="errors?.waive_right_to_withdraw">
                            <ng-container *ngFor="let err of errors.waive_right_to_withdraw">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </p>
                </ng-container>

                <p class="alignRight">
                    <button
                        type="submit"
                        class="keyboardButton orangeKeyboardButton"
                    >
                        <span i18n="@@continue">Continue</span>
                    </button>
                </p>
            </form>
        </ng-container>
    </div>
</course-auth-form-layout>
