<course-auth-form-layout>
    <div class="formArea">
        <h1 i18n="@@linkAccountTo">Link {{ ssoType.charAt(0).toUpperCase() + ssoType.slice(1) }} to your Tyyp account</h1>

        <form [formGroup]="form" (submit)="submit()">
            <label i18n="@@linkAccountPassword">Enter your Tyyp password for confirmation:</label>

            <p>
                <mat-form-field appearance="outline" class="passwordField">
                    <mat-label i18n="@@account.password">Password</mat-label>
                    <input
                        matInput
                        required
                        [type]="hidePassword ? 'password' : 'text'"
                        tabindex="1"
                        formControlName="password"
                        autocomplete="current-password"
                    />
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="hidePassword = !hidePassword;"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hidePassword"
                    >
                        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-hint>
                        <a
                            routerLink="/forgot-password"
                            i18n="@@forgotPassword.forgotYourPassword"
                        >Forgot your password?</a>
                    </mat-hint>
                    <mat-error *ngIf="errors?.password">
                        <ng-container *ngFor="let err of errors.password">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </p>

            <p class="alignRight">
                <button
                    type="submit"
                    tabindex="2"
                    class="keyboardButton orangeKeyboardButton"
                >
                    <span i18n="@@continue">Continue</span>
                </button>
            </p>
        </form>
    </div>
</course-auth-form-layout>
