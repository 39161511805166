import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Class} from '../../../models/class';
import {StateService} from '../../../services/state.service';
import {UserService} from '../../../shared/services/user.service';
import {catchError, first, switchMap} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {SsoTypes} from '../../../models/enums/ssoTypes';
import {User} from '../../../models/user';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'course-link-account',
    templateUrl: './linkAccount.component.html',
    styleUrls: ['./linkAccount.component.scss'],
})
export class LinkAccountComponent implements OnInit {
    errors: { [field: string]: string[] } = {};
    class: Class;
    form: FormGroup<{
        password: FormControl<string>;
    }>;
    email: string;
    ssoType: SsoTypes;
    externalId: string;
    hidePassword = true;


    constructor(
        private stateService: StateService,
        private userService: UserService,
        private cd: ChangeDetectorRef,
        private snackBar: MatSnackBar,
    ) {
    }

    ngOnInit() {
        this.setSsoData();

        this.form = new FormGroup({
            password: new FormControl<string>('')
        });
    }

    setSsoData() {
        const ssoData = this.stateService.getSsoAccountLinkData();

        this.email = ssoData.email;
        this.ssoType = ssoData.ssoType;
        this.externalId = ssoData.externalId;
    }

    submit() {
        return this.userService.linkSsoAccount(
            this.email,
            this.ssoType,
            this.externalId,
            this.form.get('password').value
        ).pipe(
            first(),
            switchMap(
                (success: boolean) => {
                    return this.userService.getLoggedInUser();
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.form.controls.password.setErrors(err.error.errors.password);
                        }
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            (user: User) => {
                this.cd.detectChanges();
                this.loginCompleted(user);
            }
        );

        return false;
    }

    loginCompleted(user: User) {
        this.stateService.setCartItems(0);
        this.snackBar.open(
            $localize `:@@auth.welcomeBack:Welcome back` + ', ' + user.firstName + '!',
            '',
            {
                duration: 5000,
                panelClass: ['infoSnackbar']
            }
        );
    }
}

