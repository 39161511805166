<course-auth-form-layout>
    <div class="formArea">
        <h1 *ngIf="!success">
            <ng-container *ngIf="class">
                <ng-container i18n="@@selfJoin.joinClass">Join the class</ng-container>
                "{{ class.name }}"
            </ng-container>

            <ng-container *ngIf="!class" i18n="@@selfJoin.join">Create your account</ng-container>
        </h1>
        <ng-container *ngIf="success">
            <div class="dialog success">
                <h2 i18n="@@selfJoin.successTitle">Welcome to Tyyp!</h2>

                <p
                    i18n="@@selfJoin.success"
                >We have sent your account details to your e-mail.</p>
            </div>

            <p class="listActions">
                <a routerLink="/login" mat-flat-button color="primary">
                    <mat-icon>login</mat-icon>
                    <ng-container i18n="@@login.login">Log in</ng-container>
                </a>
            </p>
        </ng-container>

        <ng-container *ngIf="!success">
            <ng-container *ngIf="emailAlreadyExists">
                <div class="dialog error">
                    <h2
                        i18n="@@activateLicense.emailAlreadyExistsTitle"
                    >E-mail already in use</h2>
                    <ng-container i18n="@@selfJoin.emailAlreadyExists">
                        <p>There is already an account registered on this e-mail address.</p>
                        <p>
                            To join this class with your existing account, simply
                            <a routerLink="/login">login to your account</a>
                            . After logging in, you may fill in the class code in your account settings.
                        </p>
                    </ng-container>
                </div>

                <p class="listActions">
                    <a routerLink="/login" mat-flat-button color="primary">
                        <mat-icon>login</mat-icon>
                        <ng-container i18n="@@login.login">Log in</ng-container>
                    </a>
                </p>
            </ng-container>

            <form [formGroup]="form" (submit)="submit()">
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@email">E-mail</mat-label>
                        <input type="email" matInput required formControlName="email">
                        <mat-error *ngIf="errors?.email">
                            <ng-container *ngFor="let err of errors.email">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@firstName">First name</mat-label>
                        <input matInput required formControlName="firstName">
                        <mat-error *ngIf="errors?.first_name">
                            <ng-container *ngFor="let err of errors.first_name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@name">Name</mat-label>
                        <input matInput required formControlName="name">
                        <mat-error *ngIf="errors?.name">
                            <ng-container *ngFor="let err of errors.name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@selfJoin.selfJoinCode">Class code</mat-label>
                        <input type="email" matInput [readonly]="!!class" formControlName="selfJoinCode">
                        <mat-hint *ngIf="!class" i18n="@@optional">Optional</mat-hint>
                        <mat-error *ngIf="errors?.self_join_code">
                            <ng-container *ngFor="let err of errors.self_join_code">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-checkbox
                        required
                        formControlName="acceptTerms"
                        i18n="@@acceptTerms"
                    >I have read and accept the <a routerLink="/terms" target="_blank">Terms of Service</a> and <a routerLink="/privacy" target="_blank">Privacy Policy</a>.</mat-checkbox>
                    <mat-error *ngIf="errors?.accept_terms">
                        <ng-container *ngFor="let err of errors.accept_terms">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </p>

                <p class="alignRight">
                    <button
                        type="submit"
                        class="keyboardButton orangeKeyboardButton"
                    >
                        <span i18n="@@continue">Continue</span>
                    </button>
                </p>
            </form>
        </ng-container>
    </div>
    <div *ngIf="!class" class="teacherArea">
        <p i18n="@@selfJoin.teacher">Are you a teacher?</p>
        <p>
            <a
                routerLink="/school-registration"
                mat-stroked-button
                color="primary"
                class="purchaseButton roundedButton"
                i18n="@@selfJoin.registerSchool"
            >Register your school!</a>
        </p>
    </div>
</course-auth-form-layout>
