import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StateService} from '../state.service';

@Injectable()
export class HasSsoDataGuard {
    constructor(
        private stateService: StateService,
        private router: Router
    ) {}

    canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        const ssoData = this.stateService.getSsoAccountLinkData();

        if (ssoData && ssoData.ssoType && ssoData.externalId && ssoData.email) {
            return true;
        }

        this.router.navigate(['/login']);
    }
}
