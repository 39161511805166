import {Component, OnInit} from '@angular/core';
import {catchError, first, switchMap} from 'rxjs/operators';
import {NEVER, of} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '../../../models/user';
import {UserService} from '../../../shared/services/user.service';
import {CourseService} from '../../../shared/services/course.service';
import {LanguageService} from '../../../shared/services/language.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-activate-license',
    templateUrl: './activateLicense.component.html',
    styleUrls: ['./activateLicense.component.scss'],
})
export class ActivateLicenseComponent implements OnInit {
    errors: { [field: string]: string[] } = {};
    missingBasicProduct = false;
    licenseAlreadyClaimed = false;
    invalidLicense = false;
    emailAlreadyExists = false;
    success = false;
    activeUser: User;
    courseId: number;
    hidePassword = true;

    form = new FormGroup({
        licenseCode: new FormControl<string | null>(''),
        email: new FormControl<string | null>(''),
        firstName: new FormControl<string | null>(''),
        name: new FormControl<string | null>(''),
        password: new FormControl<string | null>(''),
        acceptTerms: new FormControl<boolean | null>(false),
        waiveRightToWithdraw: new FormControl<boolean | null>(false)
    });


    constructor(
        private userService: UserService,
        private courseService: CourseService,
        private languageService: LanguageService,
        public navigationService: NavigationService,
        private stateService: StateService
    ) {
    }

    ngOnInit() {
        this.activeUser = this.stateService.getActiveUser();
    }

    submit() {
        if (this.activeUser) {
            this.activateLicense();
        } else {
            // get either the active course or the default course for the current domain to link to the order
            this.courseId = this.stateService.getActiveCourseId();

            if (this.courseId) {
                this.activateLicense();
            } else {
                this.courseService.getDefaultCourse().pipe(
                    first()
                ).subscribe(
                    (courseId) => {
                        this.courseId = courseId;
                        this.activateLicense();
                    }
                );
            }
        }

        return false;
    }

    activateLicense() {
        this.missingBasicProduct = false;
        this.licenseAlreadyClaimed = false;
        this.invalidLicense = false;
        this.emailAlreadyExists = false;

        let activationCall = this.userService.activateLicense(
            this.form.controls.licenseCode.value,
            this.form.controls.email.value,
            this.form.controls.password.value,
            this.form.controls.firstName.value,
            this.form.controls.name.value,
            this.courseId,
            this.languageService.getActiveLanguage().id,
            this.form.controls.acceptTerms.value,
            this.form.controls.waiveRightToWithdraw.value
        );

        if (this.activeUser) {
            activationCall = this.userService.activateLicenseLoggedIn(
                this.form.controls.licenseCode.value
            );
        }

        activationCall.pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.form.controls.licenseCode.setErrors(err.error.errors.license_code);
                            this.form.controls.email.setErrors(err.error.errors.email);
                            this.form.controls.password.setErrors(err.error.errors.password);
                            this.form.controls.firstName.setErrors(err.error.errors.first_name);
                            this.form.controls.name.setErrors(err.error.errors.name);
                            this.form.controls.name.setErrors(err.error.errors.accept_terms);
                            this.form.controls.name.setErrors(err.error.errors.waive_right_to_withdraw);
                        }

                        if (err.error === 'licenseAlreadyClaimed') {
                            this.licenseAlreadyClaimed = true;
                        } else if (err.error === 'invalidLicense') {
                            this.invalidLicense = true;
                        } else if (err.error === 'emailAlreadyExists') {
                            this.emailAlreadyExists = true;
                        } else if (err.error === 'missingBasicProduct') {
                            this.missingBasicProduct = true;
                        }
                    }

                    return NEVER;
                }
            ),
            switchMap(
                (res) => {
                    if (this.activeUser) {
                        return this.userService.getLoggedInUser();
                    }

                    return this.userService.login(
                        this.form.controls.email.value,
                        this.form.controls.password.value
                    ).pipe(
                        first(),
                        switchMap(
                            (success: boolean) => {
                                return this.userService.getLoggedInUser();
                            }
                        ),
                    );
                }
            )
        ).subscribe(
            () => {
                this.errors = {};
                this.success = true;
            }
        );
    }
}

