import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';
import {DownloadService} from './download.service';
import {StateService} from '../../services/state.service';
import {User} from '../../models/user';
import {LicenseFactory} from './factories/license.factory';
import {ProductTypeFactory} from './factories/productType.factory';

@Injectable()
export class LicenseService {
    constructor(
        private http: HttpClient,
        private stateService: StateService,
        private appService: AppService,
        private downloadService: DownloadService,
        private licenseFactory: LicenseFactory,
        private productTypeFactory: ProductTypeFactory
    ) {
    }

    hasAccessToBaseProductId(user: User, baseProductId: number) {
        if (user.roleId !== 4) {
            return true; // instructors have access to everything
        }

        const baseProductIds = user.activeBaseProducts.map(
            baseProduct => baseProduct.id
        );

        return baseProductIds.includes(baseProductId);
    }

    getInstructorAccessStats() {
        return this.http.get<any>(environment.apiUrl + 'licenses/instructor_access_stats');
    }

    checkHasEnoughAvailable(organizationId: number, amount: number) {
        return this.http.get<any>(
            environment.apiUrl + 'licenses/check_has_enough_available/' + organizationId + '/' + amount
        );
    }

    getOrganizationStats(id: number | string) {
        return this.http.get<any>(environment.apiUrl + 'licenses/organization_stats/' + id).pipe(
            map(
                (productTypes) => {
                    return productTypes.map(
                        (productType) => {
                            return {
                                productType: this.productTypeFactory.map(productType),
                                available: {
                                    basic: productType.available.basic,
                                    dlc: productType.available.dlc,
                                    comboDeal: productType.available.combo_deal,
                                    total: productType.available.total
                                },
                                noLicense: productType.no_license,
                                canUpgrade: productType.can_upgrade
                            };
                        }
                    );
                }
            ),
            tap(
                (stats) => {
                    if (id === 'mine') {
                        const notifications = this.stateService.getNotifications();
                        notifications.withoutLicense = stats.noLicense;
                        this.stateService.setNotifications(notifications);
                    }
                }
            )
        );
    }

    assignAvailable(importBatchId: number, productId: number) {
        return this.http.patch<any>(environment.apiUrl + 'licenses/assign_available', {
            import_batch_id: importBatchId,
            product_id: productId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    generateQuote(productId, organizationId, numLicenses, importBatchId) {
        let url = 'licenses/quote/' + productId + '/' + organizationId + '/' + numLicenses;

        if (importBatchId) {
            url += '/' + importBatchId;
        }

        return this.downloadService.downloadFile(url);
    }

    assignFreeLicenses(organizationId, productId, amount) {
        return this.http.post<any>(environment.apiUrl + 'licenses/assign_free', {
            organization_id: organizationId,
            product_id: productId,
            amount
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    activateUserLicense(id: number, productId: number) {
        return this.http.patch<any>(environment.apiUrl + 'licenses/activate_user_license', {
            id,
            product_id: productId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    getMine() {
        return this.http.get<any>(environment.apiUrl + 'licenses/mine').pipe(
            map(
                (licenses) => {
                    return licenses.map(
                        (license) => {
                            return this.licenseFactory.map(license);
                        }
                    );
                }
            )
        );
    }
}
